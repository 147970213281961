import React, { memo } from "react";
import styles from "./index.module.scss";

const SimpleFastEffective = () => {
    return (

        <div className={`${styles.main} container`}>
            <div className={styles.titleSection}>
                <div className={styles.tag}>
                    Simple, Fast, Effective
                </div>
                <h1>
                    DiscoBot seamlessly bridges Base trading on Discord
                </h1>
            </div>
            <div className={styles.gridContainer}>
                <div className={styles.gridItem}>
                    <img src="/assets/SimpleFastEffective/deposit.svg" alt="deposit" />
                    <h1>
                        Step 1 : Deposit
                    </h1>
                    <p>
                        use /Start command and DiscoBot will automatically create a wallet address for you where you can deposit your ETH.
                    </p>
                </div>

                <div className={styles.gridItem}>
                    <img src="/assets/SimpleFastEffective/bridge.svg" alt="deposit" />
                    <h1>
                        Step 2 : Bridge
                    </h1>
                    <p>
                        Bridging has never been easier, choose the amount to Bridge and click Bridge to bridge your ETH to Base Chain. Now you are ready to trade.
                    </p>
                </div>

                <div className={styles.gridItem}>
                    <img src="/assets/SimpleFastEffective/buy.svg" alt="deposit" />
                    <h1>
                        Step 3 : Buy
                    </h1>
                    <p>
                        Choose the token you want to buy by entering the contract address, choose your preferred settings and then confirm your Buy
                    </p>
                </div>

            </div>
        </div>

    )
}

export default memo(SimpleFastEffective)