import React, { memo } from "react";
import styles from "./index.module.scss";

const TokenDistribution = () => {
    return (

        <div className={`${styles.main} container`}>
            <div className={styles.titleSection}>
                <div className={styles.tag}>
                    Token Distribution
                </div>
                <h1>
                    A Fair Launch, a Fair Deal
                </h1>
                <p>
                    an exciting fusion of referral rewards and limited-time trading earnings. Dive into a thrilling new dimension of rewards, stakes, and limitless possibilities.
                </p>
            </div>
            <div className={styles.gridContainer}>
                <div className={styles.gridItem}>

                    <h1>
                        50%
                    </h1>
                    <p>
                        50% supply at launch for a smooth entry
                    </p>
                </div>

                <div className={styles.gridItem}>

                    <h1>
                        20%
                    </h1>
                    <p>
                        20% allocated to the dedicated team
                    </p>
                </div>

                <div className={styles.gridItem}>

                    <h1>
                        15%
                    </h1>
                    <p>
                        15% for rewards and referrals
                    </p>
                </div>

                <div className={styles.gridItem}>

                    <h1>
                        10%
                    </h1>
                    <p>
                        10% for marketing and ecosystem
                    </p>
                </div>

                <div className={styles.gridItem}>
                    <img src="/assets/TokenDistribution/banner.svg" alt="banner" />
                </div>

            </div>

            <div className={styles.lowerFlexBox}>
                <div>
                    <h1>
                        Be a part of the Community
                    </h1>
                    <p>
                        The DiscoBot community is growing, flourishing, and waiting for you. Join a vibrant network of traders and enthusiasts, all connected by a shared vision.
                    </p>
                </div>
                <button>
                    <img src="/assets/TokenDistribution/discordicon.svg" alt="discord" />Join Discord
                </button>
            </div>
        </div>

    )
}

export default memo(TokenDistribution)