import React, { memo } from "react";
import styles from "./index.module.scss";

const Footer = () => {
    return (
        <div className={styles.main}>
            <div className={`${styles.flexBox} container`}>
                <div className={styles.leftSection}>
                    <img src="/assets/logo.svg" alt="logo" className={styles.logo} />
                    {/* <h1>DISCOBOT</h1> */}
                    <p>
                        Your gateway to Base trading on Discord. Sleek, user-focused, and innovative, it's the next step in efficient crypto trading.
                    </p>
                </div>
                <div className={styles.rightSection}>
                    <img src="/assets/discordicon.svg" alt="logo" className={styles.logo} />
                    <img src="/assets/twittericon.svg" alt="logo" className={styles.logo} />
                </div>
            </div>
            <div className={`${styles.copyrights} container`}>
                <div>
                    © 2023 DiscoBot. All rights reserved.
                </div>
                <div className={styles.links}>
                    <a href="/#" >Privacy Policy</a>
                    <a href="/#" >Terms & Conditions</a>
                </div>

            </div>
        </div>
    )
}

export default memo(Footer)