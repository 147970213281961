import Footer from "components/Footer";
import Hero from "components/Hero";
import NavBar from "components/NavBar";
import SimpleFastEffective from "components/SimpleFastEffective";
import TokenDistribution from "components/TokenDistribution";
import WhyChooseDiscoBot from "components/WhyChooseDiscoBot";
import Discotokens from "components/discotokens";

function App() {
  return (
    <>
      <NavBar />
      <Hero />
      <SimpleFastEffective />
      <WhyChooseDiscoBot />
      <Discotokens />
      <TokenDistribution />

      <Footer />
    </>
  );
}

export default App;
