import React, { memo } from "react";
import styles from "./index.module.scss";

const DiscoTokens = () => {
    return (

        <div className={`${styles.main} container`}>
            <div className={styles.titleSection}>
                <div className={styles.tag}>
                    Introducing DISCO tokens
                </div>
                <h1>
                    DISCO Tokens: Elevate Your Earnings and Experience
                </h1>
                <p>
                    an exciting fusion of referral rewards and limited-time trading earnings. Dive into a thrilling new dimension of rewards, stakes, and limitless possibilities.
                </p>
            </div>
            <div className={styles.gridContainer}>
                <div className={styles.gridItem}>
                    <img src="/assets/discotokens/earn.svg" alt="deposit" />
                    <h1>
                        Earn $DISCO Through Referrals and Limited-Time Trading
                    </h1>
                    <p>
                        invite your friends and earn DISCO tokens with every successful referral.For a limited time Earn DISCO tokens as trading rewards.
                    </p>
                </div>

                <div className={styles.gridItem}>
                    <img src="/assets/discotokens/stake.svg" alt="stake" />
                    <h1>
                        Stake $DISCO to Enhance Your Rewards
                    </h1>
                    <p>
                        Unlock the full potential of your DISCO tokens by staking them. Upgrade your levels and access exclusive benefits and rewards
                    </p>
                </div>

            </div>
        </div>

    )
}

export default memo(DiscoTokens)