import React, { memo } from "react";
import styles from "./index.module.scss";

const WhyChooseDiscoBot = () => {
    return (

        <div className={`${styles.main} container`}>
            <div className={styles.titleSection}>
                <div className={styles.tag}>
                    Why Choose DiscoBot?
                </div>
                <h1>
                    Features Tailored for You
                </h1>
                <p>
                    Designed with the user in mind
                </p>
            </div>
            <div className={styles.gridContainer}>
                <div className={styles.gridItem}>
                    <img src="/assets/WhyChooseDiscoBot/Effortless Base Bridging.svg" alt="deposit" />
                    <h1>
                        Effortless Base Bridging
                    </h1>
                    <p>
                        Effortless Base Bridging  with DiscoBot enables users to send ETH to a dedicated wallet, granting instant access to Base Uniswap trades, streamlining the crypto trading process.
                    </p>
                </div>

                <div className={styles.gridItem}>
                    <img src="/assets/WhyChooseDiscoBot/Fast Trade Execution.svg" alt="deposit" />
                    <h1>
                        Fast Trade Execution
                    </h1>
                    <p>
                        DiscoBot provides lightning-fast trade execution, saving both time and money. By minimizing waiting times and maximizing efficiency, it elevates the trading experience.
                    </p>
                </div>

                <div className={styles.gridItem}>
                    <img src="/assets/WhyChooseDiscoBot/Base Pair Charts on Discord.svg" alt="deposit" />
                    <h1>
                        Base Pair Charts on Discord
                    </h1>
                    <p>
                        Stay informed and agile by Access Base pair charts directly on Discord, enabling real-time insights and analysis without leaving the platform. Essential data right at your fingertips.
                    </p>
                </div>

                <div className={styles.gridItem}>
                    <img src="/assets/WhyChooseDiscoBot/Enhanced Security .svg" alt="deposit" />
                    <h1>
                        Enhanced Security
                    </h1>
                    <p>
                        Leveraging advanced encryption, we ensure your assets are protected. Trade with confidence, knowing that safety and integrity are at the core of our platform.
                    </p>
                </div>

            </div>
        </div>

    )
}

export default memo(WhyChooseDiscoBot)