import React, { memo } from "react";
import styles from "./index.module.scss";

const NavBar = () => {
    return (
        <div className={`${styles.main} container`}>
            <div className={styles.logo}>
                <img src="/assets/logo.svg" alt="logo" />
            </div>
            <div className={styles.right}>
                <button><img src="/assets/discordicon.svg" alt="discord" />Join Discord</button>
            </div>
        </div>
    )
}

export default memo(NavBar)