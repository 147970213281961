import React, { memo } from "react";
import styles from "./index.module.scss";

const Hero = () => {
    return (
        <div className={styles.bg}>
            <div className={`${styles.main} container`}>
                <div className={styles.titleSection}>
                    {/* <img src="/assets/hero/main.png" alt="banner" /> */}
                    <div className={styles.tag}>
                        Introducing DiscoBot
                    </div>
                    <h1 className={styles.green}>
                        <div>
                            Disco is your best crypto
                        </div>
                    </h1>
                    <h1 className={styles.red}>
                        <div>
                            companion in town
                        </div>
                    </h1>
                    <p>
                        Your gateway to Base trading on Discord. Sleek, user-focused, and innovative, it's the next step in efficient crypto trading.
                    </p>
                </div>
                <div className={styles.discordBanner}>
                    <img src="/assets/hero/banner.svg" alt="banner" className={styles.web} />
                    <img src="/assets/hero/banner_mobile.svg" alt="banner" className={styles.mobile} />
                </div>
            </div>
        </div>
    )
}

export default memo(Hero)